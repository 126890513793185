import { useState, useEffect } from 'react';
import Header from '../../components/Header/Header';
import {
	Button,
	Checkbox,
	Container,
	FormControlLabel,
	FormGroup,
	Typography,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import CryptoJs from 'crypto-js';
import axios from 'axios';

const Adicionais = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const valorTamanho = location.state.valor;
	const tamanhoAcai = location.state.tamanhoAcai;

	const [carrinho, setCarrinho] = useState([]);
	const [adicionais, setAdicionais] = useState([]);
	const [checkedItemsList1, setCheckedItemsList1] = useState([]);
	const [checkedItemsList2, setCheckedItemsList2] = useState([]);
	const [acompanhamentos, setAcompanhamentos] = useState([]);
	const maxFreeSelection = tamanhoAcai === '550ml' ? 5 : 3;

	const getAdicionais = async () => {
		try {
			const response = await axios.get(
				'https://acaimaniadelivery.com:3001/adicionais'
			);
			const adicionais = response.data.filter(
				(adicional) => adicional.disponivel
			);
			setAdicionais(adicionais);
			const acompanhamentos = adicionais.filter(
				(adicional) => adicional.acompanhamento
			);
			setAcompanhamentos(acompanhamentos);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		getAdicionais();
		const carrinhoSalvo = Cookies.get('pedidos');
		console.log(carrinhoSalvo);
		if (carrinhoSalvo) {
			const decryptedCarrinho = CryptoJs.AES.decrypt(
				carrinhoSalvo,
				'chave-pedidos-acaimania'
			).toString(CryptoJs.enc.Utf8);

			setCarrinho(JSON.parse(decryptedCarrinho));
		}
	}, []);

	const handleProsseguir = () => {
		const selectedItems = [...checkedItemsList1, ...checkedItemsList2];
		const novoPedido = {
			adicionais: selectedItems.join(', '),
			tamanhoAcai: tamanhoAcai,
			valorPedido: parseFloat(valorTotal),
		};
		const novoCarrinho = [...carrinho, novoPedido];

		const encryptedCarrinho = CryptoJs.AES.encrypt(
			JSON.stringify(novoCarrinho),
			'chave-pedidos-acaimania'
		).toString();

		setCarrinho(novoCarrinho);
		Cookies.set('pedidos', encryptedCarrinho, { expires: 7 });

		navigate('/carrinho');
	};

	const handleCheckboxChangeList1 = (item) => {
		if (checkedItemsList1.includes(item)) {
			setCheckedItemsList1(checkedItemsList1.filter((i) => i !== item));
		} else if (checkedItemsList1.length < maxFreeSelection) {
			setCheckedItemsList1([...checkedItemsList1, item]);
		} else {
			setCheckedItemsList1([item]);
		}

		setCheckedItemsList2((prevCheckedItemsList2) =>
			prevCheckedItemsList2.filter(
				(list2Item) => !checkedItemsList1.includes(list2Item)
			)
		);
	};

	const handleCheckboxChangeList2 = (item) => {
		const newCheckedItemsList2 = [...checkedItemsList2];
		if (newCheckedItemsList2.includes(item)) {
			newCheckedItemsList2.splice(newCheckedItemsList2.indexOf(item), 1);
		} else {
			newCheckedItemsList2.push(item);
		}
		setCheckedItemsList2(newCheckedItemsList2);
	};

	const valor = checkedItemsList2.reduce((acc, item) => {
		const selectedItem = adicionais.find(
			(adicional) => adicional.nome === item
		);
		return acc + (selectedItem ? parseFloat(selectedItem.preco) : 0);
	}, 0);

	let valorTotal = valorTamanho + valor;

	return (
		<>
			<Header />
			<Container sx={{ marginTop: '5rem' }}>
				<Typography
					color='purple'
					variant='h5'
					sx={{
						marginBottom: '1rem',
						marginTop: '1.2rem',
					}}
				>
					ACOMPANHAMENTOS
				</Typography>
				<FormGroup>
					{acompanhamentos.map((acompanhamento) => (
						<FormControlLabel
							key={acompanhamento.id}
							control={
								<Checkbox
									checked={checkedItemsList1.includes(
										acompanhamento.nome
									)}
									onChange={() =>
										handleCheckboxChangeList1(
											acompanhamento.nome
										)
									}
								/>
							}
							label={`${acompanhamento.nome}`}
						/>
					))}
				</FormGroup>
				<Typography
					variant='h5'
					gutterBottom
				></Typography>

				<Typography
					color='purple'
					variant='h5'
					sx={{
						marginBottom: '1rem',
						marginTop: '1.2rem',
					}}
				>
					ADICIONAIS
				</Typography>
				<FormGroup>
					{adicionais.map((adicional) => (
						<FormControlLabel
							key={adicional.id}
							control={
								<Checkbox
									checked={checkedItemsList2.includes(
										adicional.nome
									)}
									onChange={() =>
										handleCheckboxChangeList2(
											adicional.nome
										)
									}
								/>
							}
							label={`${adicional.nome} - R$${adicional.preco}`}
						/>
					))}
				</FormGroup>
				<Typography
					variant='h5'
					gutterBottom
				>
					Total: R${valorTotal}
				</Typography>
				<Button
					variant='contained'
					color='secondary'
					sx={{ mb: '1rem' }}
					onClick={handleProsseguir}
				>
					Adicionar ao carrinho
				</Button>
			</Container>
		</>
	);
};

export default Adicionais;
