import {
	Card,
	CardActionArea,
	CardContent,
	CardMedia,
	Typography,
	Grid,
} from '@mui/material';
import Header from '../../components/Header/Header';
import Acai from '../menu/acai.jpg';
import AcaiP from './acaip.jpg';
import Tigela from './tigela.jpg';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useState, useEffect } from 'react';

const Menu = () => {
	const navigate = useNavigate();
	const [copos, setCopos] = useState([]);

	const handleNavigate = (tamanho, valor) => {
		navigate('/adicionais', {
			state: {
				tamanhoAcai: tamanho,
				valor: parseFloat(valor),
			},
		});
	};

	const getCopo = async () => {
		const response = await axios.get(
			'https://acaimaniadelivery.com:3001/copos'
		);
		setCopos(response.data);
	};

	useEffect(() => {
		getCopo();
	}, []);

	return (
		<>
			<Header />
			<Grid
				container
				spacing={2}
				marginTop='3.5rem'
			>
				{copos.map((copo) => (
					<Grid
						item
						xs={12}
						sm={4}
					>
						<Card sx={{ Width: '200px' }}>
							<CardActionArea
								onClick={() =>
									handleNavigate(copo.tamanho, copo.preco)
								}
							>
								<CardMedia
									component='img'
									alt='acai'
									height='500'
									image={
										copo.tamanho === '250ml'
											? AcaiP
											: copo.tamanho === '300ml'
											? Tigela
											: Acai
									}
								/>
								<CardContent>
									<Typography
										gutterBottom
										variant='h5'
										component='div'
									>
										Açai {copo.tamanho}
									</Typography>
									<Typography
										gutterBottom
										variant='subtitle1'
									>
										R$ {copo.preco} -{' '}
										{copo.tamanho === '550ml' ? '5' : '3'}{' '}
										acompanhamentos
									</Typography>
								</CardContent>
							</CardActionArea>
						</Card>
					</Grid>
				))}
			</Grid>
		</>
	);
};

export default Menu;
